/**
 * @description
 * WebView Bridge를 사용하기 위한 Hook이며 Method들은 문서를 참고하여 작성하기
 * https://github.com/classtinginc/classting-rn/wiki/WebView-Bridge
 * @returns
 * - getTokenFromBridge: WebView에서 Token을 가져오는 함수
 */

export class WebViewBridgeCallbackFailedError extends Error {
  constructor(message) {
    super(message);
    // todo: WebView에서 serialize한 error가 오도록 변경되면 deserialize 처리하기
    this.message = message ?? 'Execution failed while calling bridge callback In WebView';
  }
}

function useWebViewBridge() {
  const postWebViewMessage = (webViewMessageObject) => {
    if (window && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify(webViewMessageObject),
      );
    }
  };

  const postWebViewMessageWithCallback = ({
    messageObject,
    callbackKey,
    isDisableTimeout = false,
    timeout = 5000,
  }) => (
    new Promise((resolve, reject) => {
      window.bridgeCallbacks = window.bridgeCallbacks ?? {};
      window.bridgeErrorCallbacks = window.bridgeErrorCallbacks ?? {};

      const cleanBridgeCallbacks = () => {
        delete window.bridgeCallbacks?.[callbackKey];
        delete window.bridgeErrorCallbacks?.[callbackKey];
      };

      window.bridgeCallbacks[callbackKey] = (stringifiedParam) => {
        const data = JSON.parse(stringifiedParam);
        resolve(data);
        cleanBridgeCallbacks();
      };

      window.bridgeErrorCallbacks[callbackKey] = (errorMessage) => {
        reject(new WebViewBridgeCallbackFailedError(errorMessage));
        cleanBridgeCallbacks();
      };

      setTimeout(() => {
        if (
          (window.bridgeCallbacks?.[callbackKey]
            || window.bridgeErrorCallbacks?.[callbackKey])
            && !isDisableTimeout
        ) {
          reject(new WebViewBridgeCallbackFailedError('Timeout while calling bridge In WebView'));
          cleanBridgeCallbacks();
        }
      }, timeout);

      postWebViewMessage(messageObject);
    })
  );

  const getTokenFromBridge = () => postWebViewMessageWithCallback({
    messageObject: {
      type: 'get_token',
      category: 'auth',
      callback: 'feebackClasstalk',
    },
    callbackKey: 'feebackClasstalk',
  });

  return {
    postWebViewMessage,
    getTokenFromBridge,
  };
}

export default useWebViewBridge;
