import {
  call, put, take,
} from 'redux-saga/effects';
import * as actions from './actions';
import * as services from './services';

export function* fetchClazz() {
  while (true) {
    try {
      const { payload: { cid } } = yield take(actions.FETCH_CLAZZ_REQUEST);
      const response = yield call(services.fetchClazz, { cid });
      yield put({
        type: actions.FETCH_CLAZZ_SUCCESS,
        payload: { clazz: response.data.data },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_CLAZZ_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* fetchNewclazz() {
  while (true) {
    try {
      const { payload: { cid } } = yield take(actions.FETCH_NEWCLAZZ_REQUEST);
      const response = yield call(services.fetchNewclazz, { cid });
      yield put({
        type: actions.FETCH_NEWCLAZZ_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_NEWCLAZZ_FAILURE,
        payload: { error },
      });
    }
  }
}

export default {
  fetchClazz,
  fetchNewclazz,
};
