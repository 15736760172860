import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { forEach, keys } from 'lodash/fp';
import * as Sentry from '@sentry/browser';

const S = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  `,
  Image: styled.img``,
  Paragraph: styled.p`
    font-weight: 400;
  `,
};

class ErrorBoundary extends Component {
  state = { error: null }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      forEach((key) => {
        scope.setExtra(key, info[key]);
      }, keys(info));
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    return error
      ? (
        <S.Wrapper>
          <S.Paragraph>
            서비스에 문제가 발생했습니다. 고객센터로 문의해주세요.
          </S.Paragraph>
        </S.Wrapper>
      ) : children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
};

export default ErrorBoundary;
