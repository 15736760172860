import { InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts';
import {
  CLASSTING_OIDC_URL,
  CLASSTING_CLIENT_ID,
  HOST_URL,
} from '.';

export const oidcConfig = {
  authority: `${CLASSTING_OIDC_URL}/oidc`,
  client_id: CLASSTING_CLIENT_ID,
  redirect_uri: `${HOST_URL}/auth/callback`,
  silent_redirect_uri: `${HOST_URL}/auth/callback`,
  response_type: 'code',
  scope: 'openid email profile',
  userStore: new WebStorageStateStore({
    store: new InMemoryWebStorage(),
  }),
};
