import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { btnArrowBack } from '../../../common/assets';
import chatTranslateInstance from '../../../common/services/chatTranslate';

const S = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #fff;
  `,
  Header: styled.header`
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1200px;
  `,
  Title: styled.h2`
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.87);
  `,
};

const Img = styled.img`
`;
const i18nInstance = chatTranslateInstance.getChatTranslate().geti18Instance();

const AppBar = ({ className, location }) => (
  <S.Wrapper className={className}>
    <S.Header>
      <Link to={{ pathname: '/', state: { from: location.pathname } }}>
        <Img src={btnArrowBack} />
      </Link>
      <S.Title>
        { i18nInstance.t('MESSAGE_SELECT_CLASS') }
      </S.Title>
    </S.Header>
  </S.Wrapper>
);

AppBar.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

AppBar.defaultProps = {
  className: undefined,
};

export default withRouter(AppBar);
