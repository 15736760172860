import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Dayjs from 'dayjs';

const S = {
  ItemWrapper: styled.div`
    width: 100%;
    height: 60px;
    padding: 6px 10px;

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.07);
  `,
  ItemMain: styled.div`
    width: 100%;
    height: 100%;
    padding: 4px 0;
  `,
  Item: styled.div`
    margin-left: 16px;
    display: inline-block;
  `,
  ItemTitle: styled.div`
    width: calc(100vw - 78px);
    height: 20px;

    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);

    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  `,
  ItemTitleBody: styled.div`
    display: inline-block;
    width: calc(100vw - 138px);

    font-size: 14px;
    color: rgba(0, 0, 0, 0.87);

    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  `,
  LatestMessageAt: styled.div`
    display: inline-block;
    width: 60px;

    font-size: 11px;
    vertical-align: super;
    color: rgba(0, 0, 0, 0.5);
  `,
  ItemSubtitle: styled.div`
    height: 20px;
    width: calc(100vw - 78px);
    padding-top: 4px;

    font-size: 13px;
    color: #000;

    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  `,
  LatestMessage: styled.div`
    display: inline-block;
  `,
  CountUnread: styled.div`
    float: right;
    width: 12px;
    height: 12px;
    margin: 4px 4px 0 0;
    padding-left: 3px;

    border-radius: 6px;
    background-color: #00c896;

    font-size: 10px;
    color: #ffffff;
  `,
};

const Img = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;
  border-radius: 40px;
`;

class ChannelItem extends Component {
  componentDidMount() {
  }

  convertDate = (date) => (Dayjs(new Date(date)).format('YYYY-MM-DD'));

  getChannelName = () => {
    const {
      channel,
    } = this.props;

    if (channel.data.clazz) {
      return channel.data.clazz.name;
    }

    return channel.data.name;
  }

  getChannelMemberName = () => {
    const {
      channel,
      userInfo,
    } = this.props;

    const { clazz, name } = channel.data;

    if (!clazz) {
      return name;
    }

    let memberName;

    try {
      const { teacher, parent, student } = channel.data.clazz;

      if (parent && parent.id === userInfo.id) {
        memberName = teacher && teacher.name;
      } else if (student && student.id === userInfo.id) {
        memberName = teacher && teacher.name;
      } else if (teacher && teacher.id === userInfo.id) {
        memberName = (parent && parent.name) || (student && student.name);
      }
    } catch (error) {
      //
    }

    return memberName || name;
  };

  render() {
    const {
      channel,
      latestMessage,
      location,
    } = this.props;

    return (
      <Link to={{ pathname: `/chats?channelID=${channel.data.id}`, state: { from: location.pathname } }}>
        <S.ItemWrapper>
          <S.ItemMain>
            <Img src={channel.data.image} />
            <S.Item>
              <S.ItemTitle>
                <S.ItemTitleBody>{this.getChannelMemberName()}</S.ItemTitleBody>
                <S.LatestMessageAt>{this.convertDate(channel.data.last_message_at)}</S.LatestMessageAt>
              </S.ItemTitle>
              <S.ItemSubtitle>
                <S.LatestMessage>{latestMessage}</S.LatestMessage>
                {(channel.countUnread() !== 0) ? (<S.CountUnread>{channel.countUnread()}</S.CountUnread>) : null}
              </S.ItemSubtitle>
            </S.Item>
          </S.ItemMain>
        </S.ItemWrapper>
      </Link>
    );
  }
}

ChannelItem.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  channel: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
      updated_at: PropTypes.string.isRequired,
      last_message_at: PropTypes.string,
      clazz: PropTypes.shape({
        name: PropTypes.string,
        teacher: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        student: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        parent: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        admin: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
        member: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
    countUnread: PropTypes.func,
  }).isRequired,
  latestMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]).isRequired,
  userInfo: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(ChannelItem);
