import {
  call, put, take,
} from 'redux-saga/effects';
import * as actions from './actions';
import * as services from './services';

export function* fetchClazzes() {
  while (true) {
    try {
      yield take(actions.FETCH_CLAZZES_REQUEST);
      const response = yield call(services.fetchClazzes);
      yield put({
        type: actions.FETCH_CLAZZES_SUCCESS,
        payload: { clazzes: response.data.data },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_CLAZZES_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* fetchNewclazzes() {
  while (true) {
    try {
      yield take(actions.FETCH_NEWCLAZZES_REQUEST);
      const response = yield call(services.fetchNewclazzes);
      yield put({
        type: actions.FETCH_NEWCLAZZES_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_NEWCLAZZES_FAILURE,
        payload: { error },
      });
    }
  }
}

export default {
  fetchClazzes,
  fetchNewclazzes,
};
