import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Redirect, Route, Switch, withRouter,
} from 'react-router-dom';
import { compose, map } from 'lodash/fp';
import ReactGA from 'react-ga';
import PrivateRoutes from './PrivateRoutes';
import routes from '../routes';

const Routes = ({ location, history }) => {
  const info = useSelector((state) => state.users.info);

  useEffect(() => {
    const pagePath = `${location.pathname}${location.search}`;

    ReactGA.pageview(pagePath);
  }, [location, history, info]);

  useEffect(() => {
    window.parent.postMessage('chat:load', '*');
  }, []);

  return (
    <Route>
      <Switch>
        {compose(
          map(({ ...otherProps }) => (
            <PrivateRoutes {...otherProps} history={history} />
          )),
        )(routes)}
        <Redirect to="/not-found" />
      </Switch>
    </Route>
  );
};

Routes.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Routes);
