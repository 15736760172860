import { combineReducers } from 'redux';
import { reducer as clazz } from '../clazz';
import { reducer as clazzes } from '../clazzes';
import { reducer as members } from '../members';
import { reducer as users } from '../users';

const reducers = {
  clazz,
  clazzes,
  members,
  users,
};

export default combineReducers(reducers);
