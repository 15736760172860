import { httpClient } from '../common/utils';
import { CLASSTING_API_URL, CLASSTING_CHAT_TOKEN_API_URL } from '../common/constants';

export function* fetchUser({ id }) {
  return yield httpClient.get(`${CLASSTING_API_URL}/users/${id}`);
}

export function* fetchUserMe() {
  return yield httpClient.get(`${CLASSTING_API_URL}/users/me`);
}

export function* fetchChatTokenMe() {
  return yield httpClient.get(`${CLASSTING_CHAT_TOKEN_API_URL}/users/me`);
}

export function* setRegisterUsers(users) {
  return yield httpClient.post(`${CLASSTING_CHAT_TOKEN_API_URL}/users/register-users`, users);
}
