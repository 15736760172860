const gradeTable = {
  none: '',
  pre_kindergarten: '',
  kindergarten: '',
  first: '1',
  second: '2',
  third: '3',
  fourth: '4',
  fifth: '5',
  sixth: '6',
  seventh: '1',
  eighth: '1',
  ninth: '2',
  tenth: '3',
  eleventh: '1',
  twelfth: '2',
  higher_education: '3',
  adult: '',
};

export const convertGradeString = (grade, isHyphen) => {
  try {
    if (isHyphen) {
      return gradeTable[grade] ? `${gradeTable[grade]} -` : '';
    }

    return gradeTable[grade];
  } catch {
    return '';
  }
};
