import { httpClient } from '../common/utils';
import { CLASSTING_API_URL, CLASSTING_APIS_URL } from '../common/constants';

export function* fetchClazzes() {
  return yield httpClient.get(`${CLASSTING_API_URL}/users/me/joined_classes`);
}

export function* fetchNewclazzes() {
  return yield httpClient.get(`${CLASSTING_APIS_URL}/classroom-service/classrooms`);
}
