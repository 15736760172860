import { httpClient } from '../common/utils';
import { CLASSTING_API_URL, CLASSTING_APIS_URL } from '../common/constants';

export function* fetchClazz({ cid }) {
  return yield httpClient.get(`${CLASSTING_API_URL}/classes/${cid}`);
}

export function* fetchNewclazz({ cid }) {
  return yield httpClient.get(`${CLASSTING_APIS_URL}/classroom-service/classrooms/${cid}`);
}
