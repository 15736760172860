export const SET_AUTH_REQUEST = 'auth/SET_AUTH_REQUEST';
export const SET_AUTH_SUCCESS = 'auth/SET_AUTH_SUCCESS';
export const SET_AUTH_FAILURE = 'auth/SET_AUTH_FAILURE';

export const setAuthRequest = (payload = {}) => ({
  type: SET_AUTH_REQUEST,
  payload,
});
export const setAuthSuccess = (payload = {}) => ({
  type: SET_AUTH_SUCCESS,
  payload,
});
export const setAuthFailure = (payload = {}) => ({
  type: SET_AUTH_FAILURE,
  payload,
});
