import * as actions from './actions';

const initialState = {
  isLoadingClazzes: true,
  isLoadingNewclazzes: true,
  clazzes: null,
  newclazzes: null,
  error: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CLAZZES_REQUEST:
      return {
        ...state,
        isLoadingClazzes: true,
      };
    case actions.FETCH_CLAZZES_SUCCESS:
      return {
        ...state,
        isLoadingClazzes: false,
        clazzes: action.payload.clazzes,
      };
    case actions.FETCH_CLAZZES_FAILURE:
      return {
        ...state,
        isLoadingClazzes: false,
        error: action.payload.error,
      };
    case actions.FETCH_NEWCLAZZES_REQUEST:
      return {
        ...state,
        isLoadingNewclazzes: true,
      };
    case actions.FETCH_NEWCLAZZES_SUCCESS:
      return {
        ...state,
        isLoadingNewclazzes: false,
        newclazzes: action.payload.classrooms,
      };
    case actions.FETCH_NEWCLAZZES_FAILURE:
      return {
        ...state,
        isLoadingNewclazzes: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
