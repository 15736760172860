import { StreamChat } from 'stream-chat';
import * as Sentry from '@sentry/browser';
import { GETSTREAM_KEY } from '../constants/getstreams';

class ChatClient {
  constructor() {
    this.chatClient = new StreamChat(GETSTREAM_KEY, {
      timeout: 18000,
    });
    this.chatClient.setBaseURL('https://chat.stream-io-api.com');
  }

  async setChatClient({
    id, name, profile_image, userToken,
  }) {
    if (this.chatClient.clientID) return;

    const maxTries = 3;
    let countTries = 0;

    while (!this.chatClient.clientID && countTries < maxTries) {
      try {
        /* eslint-disable no-await-in-loop */
        await this.chatClient.connectUser(
          {
            id,
            name,
            image: profile_image,
          },
          userToken,
        );
        /* eslint-enable no-await-in-loop */
      } catch (error) {
        if (countTries >= maxTries) Sentry.captureException(error);
      }
      countTries += 1;
    }
  }

  getChatClient() {
    return this.chatClient;
  }
}

const chatClient = new ChatClient();

export default chatClient;
