import * as Sentry from '@sentry/browser';

const validateAndFixFileName = (file) => {
  const blob = file.slice(0, file.size, file.type);
  const newFileName = encodeURIComponent(file.name);

  return new File([blob], newFileName, { type: file.type });
};

export const doFileUploadRequest = (file, channel) => {
  try {
    const modifedFile = validateAndFixFileName(file);
    return channel.sendFile(modifedFile);
  } catch (error) {
    Sentry.captureException(error);
    return file;
  }
};
