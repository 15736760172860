import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Dayjs from 'dayjs';
import queryString from 'query-string';
import {
  Chat, Channel, Thread, Window,
  MessageList, MessageInput,
} from 'stream-chat-react';

import chatClientInstance from '../../common/services/chatClient';
import chatTranslateInstance from '../../common/services/chatTranslate';
import { doFileUploadRequest } from '../../common/services/validateFile';
import { AppBar } from '../components';
import { Spinner } from '../../common/components';
import {
  MESSAGE_ACTIONS_CLASSTALK,
  SERVICE_START_HOUR, SERVICE_FINISH_HOUR,
} from '../../common/constants';

import 'stream-chat-react/dist/css/v2/index.css';
import '../chats.css';

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
  `,
  AppBar: styled(AppBar)`
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 7px 9px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    z-index: 50;
  `,
};

class DirectChats extends Component {
  state = {
    chatClient: null,
    channel: null,
  };

  async componentDidMount() {
    const { match } = this.props;

    const { channelID } = queryString.parse(match.params.params);
    const chatClient = chatClientInstance.getChatClient();
    const channel = await chatClient.channel('classtalk', channelID);

    this.setState({
      chatClient,
      channel,
    });
  }

  render() {
    const {
      chatClient,
      channel,
    } = this.state;
    const { location } = this.props;
    const i18n = chatTranslateInstance.getChatTranslate();

    const searchParams = new URLSearchParams(location.search);
    const platform = searchParams.get('platform');
    const isMobile = platform === 'mobile';

    if (!channel) return (<Spinner />);
    channel.markRead();
    const handleMessageSubmit = (message) => {
      const hours = Dayjs().hour();
      channel.sendMessage({
        ...message,
        silent: (!!(hours < SERVICE_START_HOUR || hours >= SERVICE_FINISH_HOUR)),
      });
    };

    return (
      <S.Wrapper>
        <S.AppBar />
        <Chat client={chatClient} theme="messaging light" i18nInstance={i18n}>
          <Channel channel={channel}>
            <Window>
              <MessageList
                messageActions={MESSAGE_ACTIONS_CLASSTALK}
              />
              <MessageInput
                disableMentions
                doFileUploadRequest={doFileUploadRequest}
                doImageUploadRequest={doFileUploadRequest}
                overrideSubmitHandler={handleMessageSubmit}
                maxRows={4}
                grow
                shouldSubmit={isMobile ? () => false : undefined}
              />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </S.Wrapper>
    );
  }
}

DirectChats.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      params: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default DirectChats;
