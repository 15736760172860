import * as actions from './actions';

const initialState = {
  isLoadingClazzMembers: true,
  isLoadingNewclazzMembers: true,
  clazzMembers: null,
  newclazzMembers: null,
  error: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CLAZZ_MEMBERS_REQUEST:
      return {
        ...state,
        isLoadingClazzMembers: true,
      };
    case actions.FETCH_CLAZZ_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingClazzMembers: false,
        clazzMembers: action.payload.clazzMembers,
      };
    case actions.FETCH_CLAZZ_MEMBERS_FAILURE:
      return {
        ...state,
        isLoadingClazzMembers: false,
        error: action.payload.error,
      };
    case actions.FETCH_NEWCLAZZ_MEMBERS_REQUEST:
      return {
        ...state,
        isLoadingNewclazzMembers: true,
      };
    case actions.FETCH_NEWCLAZZ_MEMBERS_SUCCESS:
      return {
        ...state,
        isLoadingNewclazzMembers: false,
        newclazzMembers: action.payload.members,
      };
    case actions.FETCH_NEWCLAZZ_MEMBERS_FAILURE:
      return {
        ...state,
        isLoadingNewclazzMembers: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
