import { all, fork } from 'redux-saga/effects';
import { map } from 'lodash/fp';
import { sagas as auth } from '../auth';
import { sagas as clazz } from '../clazz';
import { sagas as clazzes } from '../clazzes';
import { sagas as members } from '../members';
import { sagas as users } from '../users';

const sagas = {
  ...auth,
  ...clazz,
  ...clazzes,
  ...members,
  ...users,
};

export default function* rootSaga() {
  yield all(map((saga) => fork(saga), sagas));
}
