import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import { imgClassEmpty } from '../../../common/assets';

const S = {
  ItemWrapper: styled.div`
    height: 52px;
    margin: 0 16px;
  `,
  Item: styled.div`
    width: calc(100% - 60px);
    margin-left: 16px;
    display: inline-block;
    vertical-align: super;
  `,
  ItemTitle: styled.div`
    width: 100%;
    height: 20px;

    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);

    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  `,
  ItemSubtitle: styled.div`
    height: 16px;

    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  `,
};

const Img = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;
  border-radius: 4px;
`;

class ClazzItem extends PureComponent {
  getClassName = ({ name }) => {
    const { schoolName } = this.props;

    return name.replace(schoolName, '');
  };

  render() {
    const { clazz, location } = this.props;

    return (
      <div>
        {clazz.is_newclass
          ? (
            <Link to={{ pathname: `/newclasses/${clazz.id}/members`, state: { from: location.pathname } }}>
              <S.ItemWrapper key={clazz.id}>
                <Img src={clazz.profile_image} onError={(event) => { event.target.onerror = null; event.target.src = imgClassEmpty; }} />
                <S.Item>
                  <S.ItemTitle>{this.getClassName(clazz)}</S.ItemTitle>
                  <S.ItemSubtitle>{clazz.year}</S.ItemSubtitle>
                </S.Item>
              </S.ItemWrapper>
            </Link>
          ) : (
            <Link to={{ pathname: `/classes/${clazz.id}/members`, state: { from: location.pathname } }}>
              <S.ItemWrapper key={clazz.id}>
                <Img src={clazz.profile_image} onError={(event) => { event.target.onerror = null; event.target.src = imgClassEmpty; }} />
                <S.Item>
                  <S.ItemTitle>{this.getClassName(clazz)}</S.ItemTitle>
                  <S.ItemSubtitle>{clazz.year}</S.ItemSubtitle>
                </S.Item>
              </S.ItemWrapper>
            </Link>
          )}
      </div>
    );
  }
}

ClazzItem.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  clazz: PropTypes.shape({
    id: PropTypes.string.isRequired,
    profile_image: PropTypes.string.isRequired,
    year: PropTypes.number,
    is_newclass: PropTypes.bool,
  }).isRequired,
  schoolName: PropTypes.string.isRequired,
};

export default withRouter(ClazzItem);
