import React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

export const nl2br = (text) => {
  if (typeof text !== 'string') {
    return text;
  }

  let id = 0;

  return text.split(newlineRegex).map((line) => {
    if (line.match(newlineRegex)) {
      id += 1;
      return React.createElement('br', { key: id });
    }

    return line;
  });
};
