import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dayjs from 'dayjs';
import { findLast } from 'lodash/fp';
import queryString from 'query-string';
import {
  Chat, Channel, Thread, Window,
  MessageList, MessageInput,
} from 'stream-chat-react';
import uuidv5 from 'uuid/v5';

import { fetchNewclazzMembersRequest } from '../../members/actions';
import { fetchNewclazzRequest } from '../../clazz/actions';
import { setRegisterUsersRequest } from '../../users/actions';
import chatClientInstance from '../../common/services/chatClient';
import chatTranslateInstance from '../../common/services/chatTranslate';
import { doFileUploadRequest } from '../../common/services/validateFile';
import { AppBar } from '../components';
import { Spinner } from '../../common/components';
import {
  MESSAGE_ACTIONS_CLASSTALK,
  SERVICE_START_HOUR, SERVICE_FINISH_HOUR,
} from '../../common/constants';

import 'stream-chat-react/dist/css/v2/index.css';
import '../chats.css';

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
  `,
  AppBar: styled(AppBar)`
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 7px 9px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    z-index: 50;
  `,
};

const UUID_NAME_SPACE = process.env.REACT_APP_UUID_NAME_SPACE;

const mapStateToProps = (state) => ({
  clazz: state.clazz,
  members: state.members,
  users: state.users,
});

const mapDispatchToProps = {
  fetchNewclazz: fetchNewclazzRequest,
  fetchNewclazzMembers: fetchNewclazzMembersRequest,
  setRegisterUsers: setRegisterUsersRequest,
};

class NewclassesChats extends Component {
  state = {
    chatClient: null,
    channel: null,
    isRegistered: null,
  };

  componentDidMount() {
    const {
      fetchNewclazz, fetchNewclazzMembers, match,
    } = this.props;

    fetchNewclazz({ cid: match.params.cid });
    fetchNewclazzMembers({ cid: match.params.cid });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { users, clazz, members } = this.props;

    if (users.isLoadingUserMe || clazz.isLoadingNewclazz || members.isLoadingNewclazzMembers) {
      return;
    }

    const { channel, isRegistered } = this.state;

    if (prevState.isRegistered === null && isRegistered === null) {
      await this.setUser();
    }

    if (prevState.channel === null && channel === null) {
      await this.setChannel();
    }
  }

  setUser = async () => {
    const {
      users, members, setRegisterUsers, location, history,
    } = this.props;

    const { mid } = queryString.parse(location.search);
    const member = findLast(['id', mid])(members.newclazzMembers);

    try {
      setRegisterUsers([{
        id: users.info.id,
        name: users.info.name,
        profile_image: users.info.profile_image,
      }, member]);

      this.setState({ isRegistered: true });
    } catch (error) {
      this.setState({ isRegistered: false });

      history.push({
        pathname: '/',
      });
    }
  }

  setChannel = async () => {
    const {
      clazz,
      members,
      users,
      history,
      location,
    } = this.props;

    const { mid } = queryString.parse(location.search);
    const member = findLast(['id', mid])(members.newclazzMembers);
    const userInfo = users.info;
    const memberMe = findLast(['id', userInfo.id])(members.newclazzMembers);
    const clazzInfo = clazz.newclazz;

    if (!(mid && member && memberMe)) {
      history.push({
        pathname: '/',
      });
      return;
    }

    const chatClient = chatClientInstance.getChatClient();
    const channelID = this.getChannelID(memberMe, member);
    const channelName = this.getChannelName(memberMe, member);

    // temporary code
    await new Promise((resolve) => setTimeout(resolve, 1000));

    try {
      const creator = memberMe;
      const receiver = member;
      const channel = await chatClient.channel('classtalk', channelID, {
        image: clazzInfo.profileImageUrl,
        name: channelName,
        members: [userInfo.id, member.id],
        clazz: {
          name: clazzInfo.name,
          profile_image: clazzInfo.profileImageUrl,
          [creator.role]: creator,
          [receiver.role]: receiver,
          participants: [creator, receiver],
        },
      });

      await channel.watch();

      this.setState({
        chatClient,
        channel,
      });
    } catch (error) {
      history.push({
        pathname: '/',
      });
    }
  }

  getChannelID = (memberMe, member) => {
    const { match } = this.props;
    const channelID = memberMe.role === 'admin'
      ? `${match.params.cid}-${memberMe.id}-${member.id}`
      : `${match.params.cid}-${member.id}-${memberMe.id}`;

    return uuidv5(channelID, UUID_NAME_SPACE);
  }

  getChannelName = (memberMe, member) => (
    memberMe.role === 'admin'
      ? `${memberMe.name} / ${member.name}`
      : `${member.name} / ${memberMe.name}`
  )

  render() {
    const {
      users, location,
    } = this.props;
    const {
      chatClient, channel,
    } = this.state;
    const { lng } = queryString.parse(location.search);
    const i18n = chatTranslateInstance.getChatTranslate();

    if (lng) chatTranslateInstance.setLanguage(lng);
    if (users.isLoadingChatTokenMe) return (<Spinner />);
    if (!channel) return (<Spinner />);

    const searchParams = new URLSearchParams(location.search);
    const platform = searchParams.get('platform');
    const isMobile = platform === 'mobile';

    const handleMessageSubmit = (message) => {
      const hours = Dayjs().hour();
      channel.sendMessage({
        ...message,
        silent: (!!(hours < SERVICE_START_HOUR || hours >= SERVICE_FINISH_HOUR)),
      });
    };

    return (
      <S.Wrapper>
        <S.AppBar />
        <Chat client={chatClient} theme="messaging light" i18nInstance={i18n}>
          <Channel channel={channel}>
            <Window>
              <MessageList
                messageActions={MESSAGE_ACTIONS_CLASSTALK}
              />
              <MessageInput
                disableMentions
                doFileUploadRequest={doFileUploadRequest}
                doImageUploadRequest={doFileUploadRequest}
                overrideSubmitHandler={handleMessageSubmit}
                maxRows={4}
                grow
                shouldSubmit={isMobile ? () => false : undefined}
              />
            </Window>
            <Thread />
          </Channel>
        </Chat>
      </S.Wrapper>
    );
  }
}

NewclassesChats.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      cid: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  fetchNewclazz: PropTypes.func.isRequired,
  fetchNewclazzMembers: PropTypes.func.isRequired,
  setRegisterUsers: PropTypes.func.isRequired,
  clazz: PropTypes.shape({
    isLoadingNewclazz: PropTypes.bool,
    newclazz: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profileImageUrl: PropTypes.string.isRequired,
    }),
  }).isRequired,
  members: PropTypes.shape({
    isLoadingNewclazzMembers: PropTypes.bool,
    newclazzMembers: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
    ),
  }).isRequired,
  users: PropTypes.shape({
    isLoadingUserMe: PropTypes.bool,
    isLoadingChatTokenMe: PropTypes.bool.isRequired,
    info: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      profile_image: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewclassesChats);
