import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../hooks';
import { Spinner } from '../../common/components';
import * as auth from '..';

const AuthCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const redirectUrl = window.sessionStorage.getItem('redirectUrl');
  const { isAuthenticated, error } = useAuth();

  useEffect(() => {
    if (isAuthenticated || error) {
      dispatch(auth.actions.setAuthRequest());
      window.sessionStorage.removeItem('redirectUrl');
      history.replace(redirectUrl ?? '/');
    }
  }, [isAuthenticated, error, dispatch, history, redirectUrl]);

  return <Spinner />;
};

export default AuthCallback;
