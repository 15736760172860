import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { btnCreateChat } from '../../../common/assets';
import chatTranslateInstance from '../../../common/services/chatTranslate';

const S = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: #fff;
  `,
  Header: styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1200px;
  `,
  Title: styled.h2`
    color: rgba(0, 0, 0, 0.87);
  `,
  Badge: styled.div`
    position: relative;
    top: -30px;
    left: 20px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: #ff4646;
  `,
};

const Img = styled.img`
`;
const i18nInstance = chatTranslateInstance.getChatTranslate().geti18Instance();

const AppBar = ({ className, isBadge, location }) => (
  <S.Wrapper className={className}>
    <S.Header>
      <S.Title>
        { i18nInstance.t('MESSAGE_CLASSTALK') }
      </S.Title>
      <Link to={{ pathname: '/classes', state: { from: location.pathname } }}>
        <Img src={btnCreateChat} />
        {
          isBadge
            ? <S.Badge />
            : <></>
        }
      </Link>
    </S.Header>
  </S.Wrapper>
);

AppBar.propTypes = {
  className: PropTypes.string,
  isBadge: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

AppBar.defaultProps = {
  className: undefined,
  isBadge: false,
};

export default withRouter(AppBar);
