import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { AuthProvider } from 'react-oidc-context';
import { ErrorBoundary } from './components';
import TrackingPixel from '../common/components/TrackingPixel';
import { Routes } from './containers';
import { GlobalStyle, theme } from './styles';
import configureStore from './configureStore';
import { oidcConfig } from '../common/constants/oidcConfig';

const store = configureStore();

const App = () => (
  <StylesProvider injectFirst>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <AuthProvider {...oidcConfig}>
            <ErrorBoundary>
              <TrackingPixel />
              <GlobalStyle />
              <Routes />
            </ErrorBoundary>
          </AuthProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </Provider>
  </StylesProvider>
);

export default App;
