import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Dayjs from 'dayjs';
import ReactGA from 'react-ga';
import queryString from 'query-string';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText,
} from '@material-ui/core';
import { Chat, ChannelList, ChannelListMessenger } from 'stream-chat-react';

import { AppBar, ChannelItem } from '../components';
import { Spinner } from '../../common/components';
import {
  SERVICE_START_HOUR, SERVICE_FINISH_HOUR,
} from '../../common/constants';
import chatClientInstance from '../../common/services/chatClient';
import chatTranslateInstance from '../../common/services/chatTranslate';
import { nl2br } from '../../common/utils';

import 'stream-chat-react/dist/css/v2/index.css';
import '../channels.css';

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
  `,
  AppBar: styled(AppBar)`
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 7px 9px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    z-index: 10;
  `,
  Main: styled.main`
    display: flex;
    width: 100%;
    padding-top: 70px;
    justify-content: center;
    background-color: #fff;
  `,
  Empty: styled.div`
    display: flex;
    margin: 100px 0;

    font-size: 16px;
  `,
  ButtonSubmit: styled(Button)`
    color: #00c896;
  `,
};

const mapStateToProps = (state) => ({
  users: state.users,
});

const mapDispatchToProps = {
};

class Channels extends Component {
  state = {
    chatClient: {},
    filters: {},
    modalOpen: false,
    sort: {},
  }

  componentDidMount() {
    const chatClient = chatClientInstance.getChatClient();
    const filters = { type: 'classtalk' };
    const sort = { last_message_at: -1 };

    this.setState({
      chatClient,
      filters,
      sort,
    });

    this.handleOutOfServiceModal();
    chatClient.on(this.handleUnreadCount);
  }

  componentWillUnmount() {
    const { chatClient } = this.state;
    if (chatClient && chatClient.off) {
      chatClient.off(this.handleUnreadCount);
    }
  }

  setGA = ({ id, role }) => (
    ReactGA.set({
      userId: id,
      dimension1: id,
      dimension2: role,
    })
  )

  handleOutOfServiceModal = () => {
    const hours = Dayjs().hour();

    if (hours < SERVICE_START_HOUR || hours >= SERVICE_FINISH_HOUR) {
      this.setState({
        modalOpen: true,
      });
    }
  }

  handleClickAlertOpen = () => {
    this.setState({
      modalOpen: true,
    });
  }

  handleAlertClose = () => {
    this.setState({
      modalOpen: false,
    });
  }

  handleAlertSubmit = () => {
    this.setState({
      modalOpen: false,
    });
  }

  handleUnreadCount = (event) => {
    const { chatClient } = this.state;
    if (event.total_unread_count) {
      this.postMessage(chatClient);
    }
  }

  postMessage = (chatClient) => {
    if (chatClient.user && chatClient.user.total_unread_count) {
      window.parent.postMessage(`${chatClient.user.total_unread_count}`, '*');
    }
  }

  render() {
    const {
      users, location,
    } = this.props;
    const {
      chatClient,
      filters,
      modalOpen,
      sort,
    } = this.state;
    const { lng } = queryString.parse(location.search);
    const isNew = !(chatClient && chatClient.tokenManager && chatClient.tokenManager.token);
    const i18n = chatTranslateInstance.getChatTranslate();
    const i18nInstance = i18n.geti18Instance();

    if (lng) chatTranslateInstance.setLanguage(lng);
    if (users.isLoadingUserMe) return (<Spinner />);
    const isModalOpen = modalOpen && users.info.role === 'parent';
    filters.members = { $in: [users.info.id] };
    this.setGA(users.info);

    return (
      <S.Wrapper>
        <S.AppBar isBadge={isNew} />
        {isNew
          ? (
            <S.Main>
              <S.Empty>{ nl2br(i18nInstance.t('MESSAGE_NO_CHANNEL_LIST')) }</S.Empty>
            </S.Main>
          )
          : (
            <Chat client={chatClient} theme="messaging light" i18nInstance={i18n}>
              <ChannelList
                filters={filters}
                sort={sort}
                List={ChannelListMessenger}
                Preview={(props) => (
                  <ChannelItem
                    userInfo={users.info}
                    {...props}
                  />
                )}
              />
            </Chat>
          )}
        <Dialog
          open={isModalOpen}
          onClose={this.handleAlertClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent style={{ marginTop: '20px' }}>
            <DialogContentText id="alert-dialog-description">
              { nl2br(i18nInstance.t('MESSAGE_OUT_OF_OFFICE')) }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <S.ButtonSubmit onClick={this.handleAlertSubmit}>
              { i18nInstance.t('MESSAGE_YES') }
            </S.ButtonSubmit>
          </DialogActions>
        </Dialog>
      </S.Wrapper>
    );
  }
}

Channels.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  users: PropTypes.shape({
    isLoadingUserMe: PropTypes.bool,
    info: PropTypes.shape({
      id: PropTypes.string,
      role: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Channels);
