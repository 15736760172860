import { httpClient } from '../common/utils';
import { CLASSTING_API_URL, CLASSTING_APIS_URL } from '../common/constants';

export function* fetchClazzMembers({ cid }) {
  return yield httpClient.get(`${CLASSTING_API_URL}/classes/${cid}/members`);
}

export function* fetchNewclazzMembers({ cid }) {
  return yield httpClient.get(`${CLASSTING_APIS_URL}/classroom-service/classrooms/${cid}/members?limit=1000`);
}
