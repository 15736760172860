import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  compose, filter, groupBy, isEmpty, map, orderBy,
} from 'lodash/fp';
import queryString from 'query-string';

import { fetchClazzesRequest, fetchNewclazzesRequest } from '../actions';
import { AppBar, SchoolItem } from '../components';
import { Spinner } from '../../common/components';
import { convertGradeString } from '../../common/services/convertGrade';
import chatTranslateInstance from '../../common/services/chatTranslate';

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
  `,
  AppBar: styled(AppBar)`
    position: fixed;
    width: 100%;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.03), 0 7px 9px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    z-index: 10;
  `,
  Main: styled.main`
    position: relative;
    width: 100%;
    padding-top: 60px;

    background-color: #fff;
  `,
  Empty: styled.div`
    margin: 100px 0;

    font-size: 16px;
    text-align: center;
  `,
};

const mapStateToProps = (state) => ({
  clazzes: state.clazzes,
});

const mapDispatchToProps = {
  fetchClazzes: fetchClazzesRequest,
  fetchNewclazzes: fetchNewclazzesRequest,
};

class Clazzes extends Component {
  componentDidMount() {
    const {
      fetchClazzes,
      fetchNewclazzes,
    } = this.props;

    fetchClazzes();
    fetchNewclazzes();
  }

  getClazzesLists = () => {
    const {
      clazzes,
    } = this.props;

    if (!(clazzes.clazzes || clazzes.newclazzes)) {
      return {};
    }

    const convertNewClazzes = map((item) => ({
      school: {
        name: item.schoolName,
        id: item.schoolId,
      },
      year: item.schoolYear,
      id: item.id,
      profile_image: item.profileImageUrl,
      is_openclass: false,
      is_newclass: true,
      name: `${item.schoolName} ${convertGradeString(item.grade, true)} ${item.name}`,
    }), clazzes.newclazzes);

    return compose(
      groupBy(('school.name')),
      orderBy(['year', 'id'], ['desc', 'desc']),
      filter(['is_openclass', false]),
    )(clazzes.clazzes.concat(convertNewClazzes));
  }

  render() {
    const {
      clazzes,
      location,
    } = this.props;
    const { lng } = queryString.parse(location.search);
    const i18nInstance = chatTranslateInstance.getChatTranslate().geti18Instance();

    if (lng) chatTranslateInstance.setLanguage(lng);
    if (clazzes.isLoadingClazzes || clazzes.isLoadingNewclazzes) return (<Spinner />);

    return (
      <S.Wrapper>
        <S.AppBar />
        <S.Main>
          {
            isEmpty(this.getClazzesLists())
              ? <S.Empty>{ i18nInstance.t('MESSAGE_NO_CLASSES') }</S.Empty>
              : map.convert({ cap: false })((value, key) => (
                <SchoolItem key={key} schoolName={key} clazzes={value} />
              ), this.getClazzesLists())
          }
        </S.Main>
      </S.Wrapper>
    );
  }
}

Clazzes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  fetchClazzes: PropTypes.func.isRequired,
  fetchNewclazzes: PropTypes.func.isRequired,
  clazzes: PropTypes.shape({
    isLoadingClazzes: PropTypes.bool,
    isLoadingNewclazzes: PropTypes.bool,
    clazzes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
    newclazzes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Clazzes);
