import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Radio, RadioGroup } from '@material-ui/core';
import {
  compose, filter, findLast, isEmpty, map, reject,
} from 'lodash/fp';
import queryString from 'query-string';

import { fetchClazzMembersRequest } from '../actions';
import { fetchClazzRequest } from '../../clazz/actions';

import { AppBar } from '../components';
import { imgUserEmpty } from '../../common/assets';
import { Spinner } from '../../common/components';
import { nl2br } from '../../common/utils';

import chatTranslateInstance from '../../common/services/chatTranslate';

const Button = styled.button`
  width: 50%;
  height: 48px;

  border-top: 1px solid #f0f0f0;
`;

const Img = styled.img`
  width: 40px;
  height: 40px;

  object-fit: cover;
  border-radius: 40px;
`;

const S = {
  Wrapper: styled.div`
    display: flex;
    width: 100%;
  `,
  AppBar: styled(AppBar)`
    position: fixed;
    width: 100%;

    background-color: #fff;
    z-index: 10;
  `,
  Headings: styled.div`
    position: fixed;
    top: 60px;
    width: 100%;
    height: 24px;
    padding: 4px 5%;

    background-color: #f5f5f5;
    z-index: 10;

    color: #9b9b9b;
  `,
  Main: styled.main`
    position: relative;
    width: 100%;
    height: 100%;
    padding: 85px 0 45px 0;
    margin: 0 auto;

    background-color: #fff;
  `,
  Item: styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    padding: 10px 20px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.07);
  `,
  ItemTitle: styled.div`
    width: 90%;
    height: 40px;
    padding: 10px;
  `,
  Empty: styled.div`
    margin: 100px 0;

    font-size: 16px;
    text-align: center;
  `,
  ActionBar: styled.div`
    position: fixed;
    width: 100%;
    bottom: 0;
    right: 0;
    background-color: #fff;
  `,
  Cancel: styled(Button)`
    color: #aaaaaa;
  `,
  Submit: styled(Button)`
    color: #30e3b8;
    border-left: 1px solid #f0f0f0;
  `,
  RadioGroup: styled(RadioGroup)`
    padding-bottom: 48px;
  `,
};

const mapStateToProps = (state) => ({
  clazz: state.clazz,
  members: state.members,
  users: state.users,
});

const mapDispatchToProps = {
  fetchClazz: fetchClazzRequest,
  fetchMembers: fetchClazzMembersRequest,
};

class Members extends Component {
  state = {
    cid: '',
    member: {},
  };

  componentDidMount() {
    const {
      fetchClazz, fetchMembers, match,
    } = this.props;

    fetchClazz({ cid: match.params.cid });
    fetchMembers({ cid: match.params.cid });
    this.setState({
      cid: match.params.cid,
    });
  }

  getMembersList = () => {
    const {
      clazz,
      members,
      users,
    } = this.props;

    if (clazz.isLoadingClazz || users.isLoadingUserMe || !members.clazzMembers) {
      return [];
    }

    const memberMe = findLast(['id', users.info.id])(members.clazzMembers);

    if (!isEmpty(memberMe) && this.isMatchRole(memberMe, 'teacher')) {
      return compose(
        filter(({ role }) => (role === 'student' || role === 'parent')),
        reject(['id', users.info.id]),
      )(members.clazzMembers);
    }
    if (!isEmpty(memberMe) && (this.isMatchRole(memberMe, 'parent') || this.isMatchRole(memberMe, 'student'))) {
      return compose(
        filter(['role', 'teacher']),
        reject(['id', users.info.id]),
      )(members.clazzMembers);
    }

    return [];
  }

  isMatchRole = ({ role }, checkRole) => (
    role === checkRole
  )

  handleRadioClick = (member) => {
    this.setState({
      member,
    });
  }

  handleCancelClick = () => {
    const { history } = this.props;

    history.push({
      pathname: '/',
    });
  }

  handleSubmitClick = () => {
    const { history } = this.props;
    const {
      member,
      cid,
    } = this.state;

    if (isEmpty(member)) {
      return;
    }

    const params = { mid: member.id };
    history.push({
      pathname: `/classes/${cid}/chats`,
      search: queryString.stringify(params),
    });
  }

  render() {
    const {
      clazz,
      members,
      users,
      location,
    } = this.props;
    const { member } = this.state;
    const { lng } = queryString.parse(location.search);
    const i18nInstance = chatTranslateInstance.getChatTranslate().geti18Instance();

    if (lng) chatTranslateInstance.setLanguage(lng);
    if (clazz.isLoadingClazz || members.isLoadingClazzMembers || users.isLoadingUserMe) return (<Spinner />);

    return (
      <S.Wrapper>
        <S.AppBar />
        <S.Headings>
          {clazz.clazz.name}
        </S.Headings>
        <S.Main>
          <S.RadioGroup>
            {
              isEmpty(this.getMembersList())
                ? <S.Empty>{ nl2br(i18nInstance.t('MESSAGE_NO_MEMBER_LIST')) }</S.Empty>
                : compose(
                  map((memberItem) => (
                    <S.Item key={memberItem.id}>
                      <Img
                        src={memberItem.profile_image}
                        onError={(event) => { event.target.onerror = null; event.target.src = imgUserEmpty; }}
                      />
                      <S.ItemTitle>{memberItem.name}</S.ItemTitle>
                      <Radio
                        value={memberItem.id}
                        onClick={() => this.handleRadioClick(memberItem)}
                      />
                    </S.Item>
                  )),
                )(this.getMembersList())
            }
          </S.RadioGroup>
        </S.Main>
        {
          isEmpty(this.getMembersList())
            ? <></>
            : (
              <S.ActionBar>
                <S.Cancel
                  onClick={this.handleCancelClick}
                >
                  { i18nInstance.t('Cancel') }
                </S.Cancel>
                <S.Submit
                  onClick={this.handleSubmitClick}
                  disabled={isEmpty(member)}
                >
                  { i18nInstance.t('MESSAGE_START_CHAT') }
                </S.Submit>
              </S.ActionBar>
            )
        }
      </S.Wrapper>
    );
  }
}

Members.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sid: PropTypes.string,
      cid: PropTypes.string,
    }).isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  fetchClazz: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  clazz: PropTypes.shape({
    isLoadingClazz: PropTypes.bool,
    clazz: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
    }),
  }).isRequired,
  members: PropTypes.shape({
    isLoadingClazzMembers: PropTypes.bool,
    clazzMembers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  users: PropTypes.shape({
    isLoadingUserMe: PropTypes.bool,
    isLoadingSetRegisterUsers: PropTypes.bool,
    info: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Members);
