export const CLASSTING_API_URL = process.env.REACT_APP_API_URL;
export const CLASSTING_OIDC_URL = process.env.REACT_APP_OIDC_URL;
export const CLASSTING_CHAT_TOKEN_API_URL = process.env.REACT_APP_CHAT_TOKEN_API_URL;
export const CLASSTING_APIS_URL = process.env.REACT_APP_APIS_URL;

export const HOST_URL = process.env.REACT_APP_HOST_URL;

export const PAGE_LOG_URL = process.env.REACT_APP_PAGE_LOG_URL;
export const EVENT_LOG_URL = process.env.REACT_APP_EVENT_LOG_URL;
export const API_LOG_URL = process.env.REACT_APP_API_LOG_URL;

export const CLASSTING_CLIENT_ID = process.env.REACT_APP_CLASSTING_CLIENT_ID;
