import {
  call, put, take,
} from 'redux-saga/effects';
import * as actions from './actions';
import * as services from './services';

export function* fetchClazzMembers() {
  while (true) {
    try {
      const { payload: { cid } } = yield take(actions.FETCH_CLAZZ_MEMBERS_REQUEST);
      const response = yield call(services.fetchClazzMembers, { cid });
      yield put({
        type: actions.FETCH_CLAZZ_MEMBERS_SUCCESS,
        payload: { clazzMembers: response.data.data },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_CLAZZ_MEMBERS_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* fetchNewclazzMembers() {
  while (true) {
    try {
      const { payload: { cid } } = yield take(actions.FETCH_NEWCLAZZ_MEMBERS_REQUEST);
      const response = yield call(services.fetchNewclazzMembers, { cid });
      yield put({
        type: actions.FETCH_NEWCLAZZ_MEMBERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_NEWCLAZZ_MEMBERS_FAILURE,
        payload: { error },
      });
    }
  }
}

export default {
  fetchClazzMembers,
  fetchNewclazzMembers,
};
