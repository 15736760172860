import { createTheme } from '@material-ui/core/styles';
import { hexToRgb } from '../../common/utils';

export default createTheme({
  palette: {
    background: {
      default: 'rgba(255, 255, 255, 0)',
    },
    primary: {
      light: '#5d6ca4',
      main: '#313955',
      contrastText: '#fff',
      soft: '#dfe2ed',
      translucent: '#a8b0ce',
    },
    secondary: {
      light: 'rgba(204, 244, 234, 0.87)',
      main: '#00c896',
      contrastText: '#fff',
    },
    error: {
      light: '#ff9800',
      main: '#e15863',
      contrastText: '#fff',
    },
    action: {
      selected: 'rgba(255, 255, 255, 0.08)',
      hover: 'rgba(255, 255, 255, 0.04)',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    blueGrey: {
      25: '#f5f7f8',
      50: '#eceff1',
      100: '#cfd8dc',
      200: '#b0bec5',
      300: '#90a4ae',
      400: '#78909c',
      500: '#607d8b',
      600: '#546e7a',
      700: '#455a64',
      800: '#37474f',
      900: '#263238',
    },
    status: {
      success: '#8bc34a',
      info: '#2196f3',
      warning: '#ff9800',
      error: '#e15863',
      note: {
        main: '#f3ab32',
        background: 'rgba(251, 229, 193, 0.87)',
      },
    },
    hexToRgb,
  },
  typography: {
    useNextVariants: true,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1280,
        lg: 1334,
        xl: 1600,
      },
    },
    fontFamily: [
      '"Apple SD Gothic Neo"',
      '"Nanum Gothic"',
      '"나눔고딕"',
      'NanumGothic',
      '"돋움"',
      'Dotum',
      '"굴림"',
      'Gulim',
      'Helvetica',
      'sans-serif',
    ].join(','),
    h2: {
      color: '#212121',
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.5,
    },
    caption: {
      color: '#212121',
      fontSize: 16,
    },
    button: {
      textTransform: 'initial',
    },
  },
  overrides: {
    MuiButton: {
      text: {
        color: '#757575',
        fontSize: '0.9375rem',
      },
      outlined: {
        color: '#616161',
        fontSize: '0.9375rem',
      },
      contained: {
        fontSize: '0.9375rem',
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTableCell: {
      head: {
        lineHeight: 'normal',
      },
      body: {
        lineHeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.8125rem',
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.12)',
      },
    },
  },
});
