export const FETCH_CLAZZ_REQUEST = 'clazzes/FETCH_CLAZZ_REQUEST';
export const FETCH_CLAZZ_SUCCESS = 'clazzes/FETCH_CLAZZ_SUCCESS';
export const FETCH_CLAZZ_FAILURE = 'clazzes/FETCH_CLAZZ_FAILURE';
export const FETCH_NEWCLAZZ_REQUEST = 'clazzes/FETCH_NEWCLAZZ_REQUEST';
export const FETCH_NEWCLAZZ_SUCCESS = 'clazzes/FETCH_NEWCLAZZ_SUCCESS';
export const FETCH_NEWCLAZZ_FAILURE = 'clazzes/FETCH_NEWCLAZZ_FAILURE';

export const fetchClazzRequest = (payload = {}) => ({
  type: FETCH_CLAZZ_REQUEST,
  payload,
});

export const fetchClazzSuccess = (payload = {}) => ({
  type: FETCH_CLAZZ_SUCCESS,
  payload,
});

export const fetchClazzFailure = (payload = {}) => ({
  type: FETCH_CLAZZ_FAILURE,
  payload,
});

export const fetchNewclazzRequest = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_REQUEST,
  payload,
});

export const fetchNewclazzSuccess = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_SUCCESS,
  payload,
});

export const fetchNewclazzFailure = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_FAILURE,
  payload,
});
