import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { isEmpty } from 'lodash/fp';

import { useAuth } from '../../../auth/hooks';

const TrackingPixel = () => {
  const { isAuthenticated, user } = useAuth();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_AUTH,
      preview: process.env.REACT_APP_GTM_ENV,
    });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && isAuthenticated && !isEmpty(user) && !isEmpty(user.profile)) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'ct.identify',
          userId: user?.profile?.sub,
          role: user?.profile?.role,
        },
      });
    }
  }, [initialized, user, isAuthenticated]);

  return null;
};

export default TrackingPixel;
