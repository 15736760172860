import { Streami18n } from 'stream-chat-react';
import {
  localesKo,
  localesEn,
  localesEs,
  localesJa,
  localesRu,
  localesKoDatetime,
} from '../assets';

class ChatTranslate {
  constructor(language = 'ko') {
    this.i18n = new Streami18n();
    this.i18n.registerTranslation('ko', localesKo, localesKoDatetime);
    this.i18n.registerTranslation('en', localesEn);
    this.i18n.registerTranslation('es', localesEs);
    this.i18n.registerTranslation('ja', localesJa);
    this.i18n.registerTranslation('ru', localesRu);
    this.i18n.setLanguage(language);
    this.i18n.init();
  }

  setLanguage(language = 'ko') {
    this.i18n.setLanguage(language.substring(0, 2).toLowerCase());
    this.i18n.init({ fallbackLng: 'en' });
  }

  getChatTranslate() {
    return this.i18n;
  }
}

const chatTranslate = new ChatTranslate();

export default chatTranslate;
