import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { Spinner } from '../../common/components';
import { useAuth } from '../../auth/hooks';

const PrivateRoutes = ({
  component: Component, routes, ...otherProps
}) => {
  const { isAuthenticated, authorize } = useAuth();

  useEffect(() => {
    authorize();
  }, [authorize]);

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (isAuthenticated) {
          return (
            <Component {...props} routes={routes} />
          );
        }

        return <Spinner />;
      }}
    />
  );
};

PrivateRoutes.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]).isRequired,
  isAuthenticated: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string,
  })),
};

PrivateRoutes.defaultProps = {
  isAuthenticated: null,
  routes: undefined,
};

export default PrivateRoutes;
