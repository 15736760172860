import { route as auth } from '../auth';
import { route as channels } from '../channels';
import { route as chats } from '../chats';
import { route as clazzes } from '../clazzes';
import { route as members } from '../members';
import { route as notFound } from '../not-found';

export default [
  auth,
  channels,
  ...chats,
  clazzes,
  ...members,
  notFound,
];
