export const FETCH_CLAZZES_REQUEST = 'clazzes/FETCH_CLAZZES_REQUEST';
export const FETCH_CLAZZES_SUCCESS = 'clazzes/FETCH_CLAZZES_SUCCESS';
export const FETCH_CLAZZES_FAILURE = 'clazzes/FETCH_CLAZZES_FAILURE';

export const FETCH_NEWCLAZZES_REQUEST = 'clazzes/FETCH_NEWCLAZZES_REQUEST';
export const FETCH_NEWCLAZZES_SUCCESS = 'clazzes/FETCH_NEWCLAZZES_SUCCESS';
export const FETCH_NEWCLAZZES_FAILURE = 'clazzes/FETCH_NEWCLAZZES_FAILURE';

export const fetchClazzesRequest = (payload = {}) => ({
  type: FETCH_CLAZZES_REQUEST,
  payload,
});

export const fetchClazzesSuccess = (payload = {}) => ({
  type: FETCH_CLAZZES_SUCCESS,
  payload,
});

export const fetchClazzesFailure = (payload = {}) => ({
  type: FETCH_CLAZZES_FAILURE,
  payload,
});

export const fetchNewclazzesRequest = (payload = {}) => ({
  type: FETCH_NEWCLAZZES_REQUEST,
  payload,
});

export const fetchNewclazzesSuccess = (payload = {}) => ({
  type: FETCH_NEWCLAZZES_SUCCESS,
  payload,
});

export const fetchNewclazzesFailure = (payload = {}) => ({
  type: FETCH_NEWCLAZZES_FAILURE,
  payload,
});
