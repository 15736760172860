import { Chats, DirectChats, NewclassesChats } from './pages';

export default [
  {
    key: 'chats',
    path: '/classes/:cid/chats',
    exact: true,
    component: Chats,
  },
  {
    key: 'direct-chats',
    path: '/chats?:params',
    exact: true,
    component: DirectChats,
  },
  {
    key: 'newclasses-chats',
    path: '/newclasses/:cid/chats',
    exact: true,
    component: NewclassesChats,
  },
];
