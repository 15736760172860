import React from 'react';
import styled, { keyframes } from 'styled-components';
import { map, range } from 'lodash/fp';

const spin = keyframes`
  0% {
    opacity: 1;
    transform: scale(1.5, 1.5);
  }

  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
`;

const S = {
  Wrapper: styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Spin: styled.div`
    transform: scale(1);
    width: 200px;
    height: 200px;
  `,
  Element: styled.div`
    :nth-child(1) {
      transform: rotate(0deg);
      transform-origin: 124px 100px;
      div {
        left: 120px;
        top: 96px;
        animation-delay: -0.888888888888889s;
      }
    }
    :nth-child(2) {
      transform: rotate(40deg);
      transform-origin: 118.38506663199999px 115.42690264px;
      div {
        left: 114.38506663199999px;
        top: 111.42690264px;
        animation-delay: -0.777777777777778s;
      }
    }
    :nth-child(3) {
      transform: rotate(80deg);
      transform-origin: 104.167556272px 123.635386072px;
      div {
        left: 100.167556272px;
        top: 119.635386072px;
        animation-delay: -0.666666666666667s;
      }
    }
    :nth-child(4) {
      transform: rotate(120deg);
      transform-origin: 88px 120.784609696px;
      div {
        left: 84px;
        top: 116.784609696px;
        animation-delay: -0.555555555555556s;
      }
    }
    :nth-child(5) {
      transform: rotate(160deg);
      transform-origin: 77.447377096px 108.208483432px;
      div {
        left: 73.447377096px;
        top: 104.208483432px;
        animation-delay: -0.444444444444444s;
      }
    }
    :nth-child(6) {
      transform: rotate(200deg);
      transform-origin: 77.447377096px 91.791516568px;
      div {
        left: 73.447377096px;
        top: 87.791516568px;
        animation-delay: -0.333333333333333s;
      }
    }
    :nth-child(7) {
      transform: rotate(240deg);
      transform-origin: 88px 79.215390304px;
      div {
        left: 84px;
        top: 75.215390304px;
        animation-delay: -0.222222222222222s;
      }
    }
    :nth-child(8) {
      transform: rotate(280deg);
      transform-origin: 104.167556272px 76.364613928px;
      div {
        left: 100.167556272px;
        top: 72.364613928px;
        animation-delay: -0.111111111111111s;
      }
    }
    :nth-child(9) {
      transform: rotate(320deg);
      transform-origin: 118.38506663199999px 84.57309736px;
      div {
        left: 114.38506663199999px;
        top: 80.57309736px;
        animation-delay: 0s;
      }
    }
    :nth-child(10) {
      transform: rotate(360deg);
      transform-origin: 124px 100px;
      div {
        left: 120px;
        top: 96px;
        animation-delay: 0.111111111111111s;
      }
    }
  `,
  Circle: styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.palette.primary.main};
    animation: ${spin} 1s linear infinite;
  `,
};

/**
 * Spinner를 사용하여 로팅 상태를 표현할 수 있습니다.
 *
 * @version 1.0.0
 * @author [chayeoi](https://github.com/chayeoi)
 */

const Spinner = (props) => (
  <S.Wrapper {...props}>
    <S.Spin>
      {map((num) => (
        <S.Element key={num}>
          <S.Circle />
        </S.Element>
      ), range(0, 10))}
    </S.Spin>
  </S.Wrapper>
);

export default Spinner;
