import axios from 'axios';

export const httpClient = axios.create({
  headers: {
    Accept: 'application/json',
  },
});

export const setTokenToRequestHeader = ({ accessToken, tokenType } = { accessToken: '', tokenType: '' }) => {
  httpClient.defaults.headers.common.Authorization = `${tokenType} ${accessToken}`;
};

export const removeTokenFromRequestHeader = () => {
  delete httpClient.defaults.headers.common.Authorization;
};
