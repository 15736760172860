import {
  call, put, take, select,
} from 'redux-saga/effects';
import * as selectors from './selectors';
import * as actions from './actions';
import * as services from './services';
import chatClientInstance from '../common/services/chatClient';

export function* fetchUser() {
  while (true) {
    try {
      const { payload: { id } } = yield take(actions.FETCH_USER_REQUEST);
      const response = yield call(services.fetchUser, { id });
      yield put({
        type: actions.FETCH_USER_SUCCESS,
        payload: { user: response.data },
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_USER_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* fetchUserMe() {
  while (true) {
    try {
      yield take(actions.FETCH_USER_ME_REQUEST);
      const response = yield call(services.fetchUserMe);
      yield put({
        type: actions.FETCH_USER_ME_SUCCESS,
        payload: { info: response.data.data },
      });
      yield put({ type: actions.FETCH_CHAT_TOKEN_ME_REQUEST });
    } catch (error) {
      yield put({
        type: actions.FETCH_USER_ME_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* fetchChatTokenMe() {
  while (true) {
    try {
      yield take(actions.FETCH_CHAT_TOKEN_ME_REQUEST);
      const userInfo = yield select(selectors.getInfo);
      if (userInfo) {
        const response = yield call(services.fetchChatTokenMe);
        chatClientInstance.setChatClient({ ...userInfo, userToken: response.data.token });
        yield put({
          type: actions.FETCH_CHAT_TOKEN_ME_SUCCESS,
          payload: { info: response.data },
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_CHAT_TOKEN_ME_FAILURE,
        payload: { error },
      });
    }
  }
}

export function* setRegisterUsers() {
  while (true) {
    try {
      const { payload: users } = yield take(actions.SET_REGISTER_USERS_REQUEST);
      yield call(services.setRegisterUsers, users);
      yield put({
        type: actions.SET_REGISTER_USERS_SUCCESS,
      });
      yield put({ type: actions.FETCH_CHAT_TOKEN_ME_REQUEST });
    } catch (error) {
      yield put({
        type: actions.SET_REGISTER_USERS_FAILURE,
        payload: { error },
      });
    }
  }
}
