import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { map } from 'lodash/fp';
import ClazzItem from '../ClazzItem';

const S = {
  Heading: styled.div`
    height: 52px;
    padding: 19px 16px;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.07);
  `,
};

const SchoolItem = ({ clazzes, schoolName }) => (
  <div>
    <S.Heading>
      {schoolName === 'undefined' ? 'ETC' : schoolName }
    </S.Heading>
    {map.convert({ cap: false })((value, key) => (
      <ClazzItem key={key} clazz={value} schoolName={schoolName} />
    ))(clazzes)}
  </div>
);

SchoolItem.propTypes = {
  clazzes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    profile_image: PropTypes.string.isRequired,
    year: PropTypes.number,
  })),
  schoolName: PropTypes.string,
};

SchoolItem.defaultProps = {
  clazzes: [],
  schoolName: null,
};

export default SchoolItem;
