export const FETCH_USER_REQUEST = 'user/FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'user/FETCH_USER_FAILURE';
export const FETCH_USER_ME_REQUEST = 'user/FETCH_USER_ME_REQUEST';
export const FETCH_USER_ME_SUCCESS = 'user/FETCH_USER_ME_SUCCESS';
export const FETCH_USER_ME_FAILURE = 'user/FETCH_USER_ME_FAILURE';
export const FETCH_CHAT_TOKEN_ME_REQUEST = 'user/FETCH_CHAT_TOKEN_REQUEST';
export const FETCH_CHAT_TOKEN_ME_SUCCESS = 'user/FETCH_CHAT_TOKEN_SUCCESS';
export const FETCH_CHAT_TOKEN_ME_FAILURE = 'user/FETCH_CHAT_TOKEN_FAILURE';
export const SET_REGISTER_USERS_REQUEST = 'user/SET_REGISTER_USERS_REQUEST';
export const SET_REGISTER_USERS_SUCCESS = 'user/SET_REGISTER_USERS_SUCCESS';
export const SET_REGISTER_USERS_FAILURE = 'user/SET_REGISTER_USERS_FAILURE';

export const fetchUserRequest = (payload = {}) => ({
  type: FETCH_USER_REQUEST,
  payload,
});
export const fetchUserSuccess = (payload = {}) => ({
  type: FETCH_USER_SUCCESS,
  payload,
});
export const fetchUserFailure = (payload = {}) => ({
  type: FETCH_USER_FAILURE,
  payload,
});
export const fetchUserMeRequest = (payload = {}) => ({
  type: FETCH_USER_ME_REQUEST,
  payload,
});
export const fetchUserMeSuccess = (payload = {}) => ({
  type: FETCH_USER_ME_SUCCESS,
  payload,
});
export const fetchUserMeFailure = (payload = {}) => ({
  type: FETCH_USER_ME_FAILURE,
  payload,
});
export const fetchChatTokenMeRequest = (payload = {}) => ({
  type: FETCH_CHAT_TOKEN_ME_REQUEST,
  payload,
});
export const fetchChatTokenMeSuccess = (payload = {}) => ({
  type: FETCH_CHAT_TOKEN_ME_SUCCESS,
  payload,
});
export const fetchChatTokenMeFailure = (payload = {}) => ({
  type: FETCH_CHAT_TOKEN_ME_FAILURE,
  payload,
});
export const setRegisterUsersRequest = (payload = {}) => ({
  type: SET_REGISTER_USERS_REQUEST,
  payload,
});
export const setRegisterUsersSuccess = (payload = {}) => ({
  type: SET_REGISTER_USERS_SUCCESS,
  payload,
});
export const setRegisterUsersFailure = (payload = {}) => ({
  type: SET_REGISTER_USERS_FAILURE,
  payload,
});
