import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Grid as MuiGrid } from '@material-ui/core';
import { getStyleValue } from '../../utils';

/**
 * Grid를 사용하여 애플리케이션의 레이아웃을 형성할 수 있습니다.
 *
 * @version 1.0.0
 * @author [chayeoi](https://github.com/chayeoi)
*/

const Grid = styled(({
  margin, marginTop, marginBottom,
  padding, paddingTop, paddingBottom,
  ...otherProps
}) => (
  <MuiGrid {...otherProps} />
))`
  ${({
    container,
    margin,
    marginTop,
    marginBottom,
    padding,
    paddingTop,
    paddingBottom,
  }) => container && css`
    margin-top: ${getStyleValue(margin.top) || marginTop};
    margin-right: ${getStyleValue(margin.right)};
    margin-bottom: ${getStyleValue(margin.bottom) || marginBottom};
    margin-left: ${getStyleValue(margin.left)};
    padding-top: ${getStyleValue(padding.top) || paddingTop};
    padding-right: ${getStyleValue(padding.right)};
    padding-bottom: ${getStyleValue(padding.bottom) || paddingBottom};
    padding-left: ${getStyleValue(padding.left)};
  `}
`;

Grid.propTypes = {
  margin: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  padding: PropTypes.shape({
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};

Grid.defaultProps = {
  margin: {},
  padding: {},
};

/** @component */
export default Grid;
