import * as actions from './actions';

const initialState = {
  isLoadingUser: true,
  user: {},
  isLoadingUserMe: true,
  info: {},
  isLoadingChatTokenMe: true,
  isLoadingSetRegisterUsers: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_USER_REQUEST:
      return {
        ...state,
        isLoadingUser: true,
      };
    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload.user,
      };
    case actions.FETCH_USER_FAILURE:
      return {
        ...state,
        isLoadingUser: true,
        user: action.payload.error,
      };
    case actions.FETCH_USER_ME_REQUEST:
      return {
        ...state,
        isLoadingUserMe: true,
      };
    case actions.FETCH_USER_ME_SUCCESS:
      return {
        ...state,
        isLoadingUserMe: false,
        info: action.payload.info,
      };
    case actions.FETCH_USER_ME_FAILURE:
      return {
        ...state,
        isLoadingUserMe: true,
        info: action.payload.error,
      };
    case actions.FETCH_CHAT_TOKEN_ME_REQUEST:
      return {
        ...state,
        isLoadingChatTokenMe: true,
      };
    case actions.FETCH_CHAT_TOKEN_ME_SUCCESS:
      return {
        ...state,
        isLoadingChatTokenMe: false,
      };
    case actions.FETCH_CHAT_TOKEN_ME_FAILURE:
      return {
        ...state,
        isLoadingChatTokenMe: true,
      };
    case actions.SET_REGISTER_USERS_REQUEST:
      return {
        ...state,
        isLoadingSetRegisterUsers: true,
      };
    case actions.SET_REGISTER_USERS_SUCCESS:
      return {
        ...state,
        isLoadingSetRegisterUsers: false,
      };
    case actions.SET_REGISTER_USERS_FAILURE:
      return {
        ...state,
        isLoadingSetRegisterUsers: true,
      };
    default:
      return state;
  }
};

export default reducer;
