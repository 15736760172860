import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Apple SD Gothic Neo', 'Nanum Gothic', 나눔고딕, NanumGothic, 돋움, Dotum, 굴림, Gulim, Helvetica, sans-serif;
    line-height: normal;
    overscroll-behavior: none;
  }

  ol, ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    background-color: transparent;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  p, figure, blockquote {
    margin: 0;
  }

  :lang(ko) {
    font-family: 'Noto Sans KR', 'Apple SD Gothic Neo', 'Nanum Gothic', 나눔고딕, NanumGothic, 돋움, Dotum, 굴림, Gulim, Helvetica, sans-serif;
  }

  :lang(en) {
    font-family: Helvetica, Arial, sans-serif;
  }

  :lang(zh-CN) {
    font-family: 'Microsoft YaHei', 微软雅黑, helvetica, arial, verdana, tahoma, sans-serif;
  }

  :lang(zh-TW) {
    font-family: 'Helvetica Neue', Helvetica, Arial, STHeiti, 'Microsoft JhengHei', 微軟正黑體, sans-serif;
  }

  :lang(ja) {
    font-family: 'Hiragino Kaku Gothic Pro', 'ヒラギノ角ゴ Pro W3', 'Meiryo', 'メイリオ', 'Osaka', 'MS PGothic', Helvetica, Arial, sans-serif;
  }

  :lang(es) {
    font-family: Helvetica, Arial, sans-serif;
  }
`;

export default GlobalStyle;
