import {
  CLASSTING_OIDC_URL,
  CLASSTING_CLIENT_ID,
} from '../../common/constants';

export const KEY_CREDENTIAL = `oidc.user:${CLASSTING_OIDC_URL}/oidc:${CLASSTING_CLIENT_ID}`;

export const setCredential = (credentialParams) => {
  sessionStorage.setItem(KEY_CREDENTIAL, JSON.stringify(credentialParams));
};

export const getCredential = () => {
  const credential = JSON.parse(sessionStorage.getItem(KEY_CREDENTIAL));

  if (credential?.token_type && credential?.access_token) {
    return {
      tokenType: credential.token_type,
      accessToken: credential.access_token,
    };
  }

  return null;
};
