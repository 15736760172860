export const FETCH_CLAZZ_MEMBERS_REQUEST = 'members/FETCH_CLAZZ_MEMBERS_REQUEST';
export const FETCH_CLAZZ_MEMBERS_SUCCESS = 'members/FETCH_CLAZZ_MEMBERS_SUCCESS';
export const FETCH_CLAZZ_MEMBERS_FAILURE = 'members/FETCH_CLAZZ_MEMBERS_FAILURE';

export const FETCH_NEWCLAZZ_MEMBERS_REQUEST = 'members/FETCH_NEWCLAZZ_MEMBERS_REQUEST';
export const FETCH_NEWCLAZZ_MEMBERS_SUCCESS = 'members/FETCH_NEWCLAZZ_MEMBERS_SUCCESS';
export const FETCH_NEWCLAZZ_MEMBERS_FAILURE = 'members/FETCH_NEWCLAZZ_MEMBERS_FAILURE';

export const fetchClazzMembersRequest = (payload = {}) => ({
  type: FETCH_CLAZZ_MEMBERS_REQUEST,
  payload,
});

export const fetchClazzMembersSuccess = (payload = {}) => ({
  type: FETCH_CLAZZ_MEMBERS_SUCCESS,
  payload,
});

export const fetchClazzMembersFailure = (payload = {}) => ({
  type: FETCH_CLAZZ_MEMBERS_FAILURE,
  payload,
});

export const fetchNewclazzMembersRequest = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_MEMBERS_REQUEST,
  payload,
});

export const fetchNewclazzMembersSuccess = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_MEMBERS_SUCCESS,
  payload,
});

export const fetchNewclazzMembersFailure = (payload = {}) => ({
  type: FETCH_NEWCLAZZ_MEMBERS_FAILURE,
  payload,
});
