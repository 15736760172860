import * as actions from './actions';

const initialState = {
  isLoadingClazz: true,
  isLoadingNewclazz: true,
  clazz: null,
  newclazz: null,
  error: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CLAZZ_REQUEST:
      return {
        ...state,
        isLoadingClazz: true,
      };
    case actions.FETCH_CLAZZ_SUCCESS:
      return {
        ...state,
        isLoadingClazz: false,
        clazz: action.payload.clazz,
      };
    case actions.FETCH_CLAZZ_FAILURE:
      return {
        ...state,
        isLoadingClazz: false,
        error: action.payload.error,
      };
    case actions.FETCH_NEWCLAZZ_REQUEST:
      return {
        ...state,
        isLoadingNewclazz: true,
      };
    case actions.FETCH_NEWCLAZZ_SUCCESS:
      return {
        ...state,
        isLoadingNewclazz: false,
        newclazz: action.payload,
      };
    case actions.FETCH_NEWCLAZZ_FAILURE:
      return {
        ...state,
        isLoadingNewclazz: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
