import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import App from './app';
import chatClientInstance from './common/services/chatClient';
import * as serviceWorker from './serviceWorker';
import { version } from '../package.json';

import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `chat-web@${version}`,
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.REACT_APP_STAGE,
});

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const init = () => {
  ReactDOM.render((
    <Router>
      <App />
    </Router>
  ), document.getElementById('chat-main'));
};

init();

serviceWorker.unregister();

window.onbeforeunload = () => {
  try {
    const client = chatClientInstance.getChatClient();

    if (client && client.wsConnection) {
      client.wsConnection.disconnect();
    }
  } catch (error) {
    Sentry.captureException(error);
  }
};
