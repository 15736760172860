import { Members, NewclassesMembers } from './pages';

export default [
  {
    key: 'members',
    path: '/classes/:cid/members',
    exact: true,
    component: Members,
  },
  {
    key: 'newclasses-members',
    path: '/newclasses/:cid/members',
    exact: true,
    component: NewclassesMembers,
  },
];
