import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { classtingAppLogo } from '../../common/assets';
import { HOST_URL } from '../../common/constants';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%
  background-color: #fff;
`;

const CenterPosition = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Img = styled.img`
  opacity: .5;
  width: 256px;
  margin-bottom: 30px;
`;

const Figure = styled.figure`
  margin: 0;
  text-align: center;
`;

const Figcaption = styled.figcaption`
  width: 220px;
  margin: 0 auto 15px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 20px;
  font-weight: 500;
`;

const Anchor = styled.a`
  && {
    display: block;
    max-width: 220px;
    margin: 0 auto 40px;
    font-size: 14px;
    text-align: center;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const NotFound = () => (
  <Wrapper>
    <CenterPosition>
      <Figure>
        <Img
          src={classtingAppLogo}
        />
        <Figcaption>
          페이지를 찾을 수 없습니다.
        </Figcaption>
      </Figure>
      <Typography component={Anchor} href={HOST_URL}>
        홈으로 이동
      </Typography>
    </CenterPosition>
  </Wrapper>
);

export default NotFound;
