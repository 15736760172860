import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth as useOidcAuth } from 'react-oidc-context';
import { useBoolean } from 'react-use';

import { setTokenToRequestHeader } from '../../common/utils';
import useWebViewBridge from './useWebViewBridge';

const useAuth = () => {
  const location = useLocation();
  const auth = useOidcAuth();
  const [isAuthenticated, setIsAuthenticated] = useBoolean(auth.isAuthenticated && !!auth.user);
  const [error, setError] = useState(auth.error);
  const { user } = auth;
  const from = location.pathname + location.search + location.hash;
  const searchParams = new URLSearchParams(location.search);
  const platform = searchParams.get('platform');

  const { getTokenFromBridge } = useWebViewBridge();

  useEffect(() => {
    if (auth.isAuthenticated && auth.user && !auth.error) {
      setIsAuthenticated(true);
    }
  }, [auth.error, auth.isAuthenticated, auth.user, setIsAuthenticated]);

  useEffect(() => {
    if (auth.error) {
      setError(auth.error);
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth.isLoading) {
      return;
    }

    if (auth.isAuthenticated) {
      setTokenToRequestHeader({
        accessToken: auth?.user?.access_token ?? '',
        tokenType: auth?.user?.token_type ?? '',
      });
    }
  }, [auth]);

  const authorize = useCallback(async () => {
    if (platform === 'mobile' && !isAuthenticated) {
      try {
        const { access_token } = await getTokenFromBridge();
        setTokenToRequestHeader({
          accessToken: access_token,
          tokenType: 'Bearer',
        });
        setIsAuthenticated(true);
        return;
      } catch (e) {
        window.sessionStorage.setItem('redirectUrl', from);
        auth.signinRedirect();
        return;
      }
    }

    if (auth.isLoading) {
      return;
    }

    if (!auth.isAuthenticated || !auth.user || auth.error) {
      window.sessionStorage.setItem('redirectUrl', from);
      auth.signinRedirect();
    }
  }, [auth, from, getTokenFromBridge, isAuthenticated, platform, setIsAuthenticated]);

  return {
    isAuthenticated,
    authorize,
    error,
    user,
  };
};

export default useAuth;
