import {
  put, take,
} from 'redux-saga/effects';
import * as userActions from '../users/actions';
import * as actions from './actions';

export function* setAuth() {
  while (true) {
    try {
      yield take(actions.SET_AUTH_REQUEST);
      yield put({ type: userActions.FETCH_USER_ME_REQUEST });
    } catch (error) {
      yield put({
        type: actions.SET_AUTH_FAILURE,
        payload: { error },
      });
    }
  }
}
