import { includes } from 'lodash/fp';
import ReactGA from 'react-ga';

const eventLogger = () => (next) => (action) => {
  const eventData = {
    category: 'chat',
    label: action.type,
    action: 'click',
  };

  if (!includes('REQUEST', action.type)) {
    ReactGA.event(eventData);
  }

  return next(action);
};

export default [eventLogger];
